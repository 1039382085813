import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Loading,
  Modal,
  Typography
} from '@shared_medialab/ui_components';
import client from 'apolloClient';
import { MatchTypesEnum, MATCH_STATUS_IDS } from 'constants/match';
import { BOOK_MATCHES, DELETE_CART } from 'gql/cart/mutations';
import { GET_CART } from 'gql/cart/queries';
import { Cart } from 'gql/cart/types/Cart';
import { money } from 'helpers/money';
import {
  useEventsCartLoadingStateContext,
  useEventsDispatchContext,
  useSelectedCountriesStateContext
} from 'providers/Events';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GET_BUYER_UPCOMING_EVENTS } from 'gql/events/queries';
import { useNavigate } from 'react-router-dom';
import { ModalKeys } from 'providers/UI/types';
import { useProjectStateContext } from 'providers/Projects';
import * as permissionConstants from 'constants/permissions';

import { ModalContent, ModalFooter } from './styled';
import MatchSidebarItem from './components/MatchSidebarItem';
import { ALL } from './constants';
import Private from '../Private';

const { Text } = Typography;

const ShoppingCartModal: FC = () => {
  //state
  const [deletedCards, setDeletedCards] = useState<string[]>([]);

  // translations
  const { t } = useTranslation(['cart', 'common']);
  // ui
  const { shoppingCart } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();
  // context
  const { unselectItems, unselectAll, initMatches } =
    useEventsDispatchContext();

  const selectedCountries = useSelectedCountriesStateContext();
  const cartLoadingOnRefetch = useEventsCartLoadingStateContext();
  const { selectedProject } = useProjectStateContext();
  // navigation
  const navigate = useNavigate();
  const onClose = useCallback(() => {
    toggleModal(ModalKeys.shoppingCart, false);
  }, [toggleModal]);

  // graphql
  const {
    data,
    loading: cartLoading,
    refetch
  } = useQuery<Cart>(GET_CART, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    skip: !selectedProject?.id,
    variables: {
      organizationId: selectedProject?.id
    }
  });

  const [deleteCart] = useMutation(DELETE_CART);

  const ids = Object.keys(selectedCountries).filter(
    key => selectedCountries[key].length > 0
  );

  const [bookMatches, { loading }] = useMutation(BOOK_MATCHES, {
    onError(error) {
      toast.error(error.message);
    },
    onCompleted() {
      if (!Object.values(selectedCountries).every(el => el.length)) {
        unselectItems(ids, MatchTypesEnum.list);
        client.refetchQueries({
          include: [GET_CART, GET_BUYER_UPCOMING_EVENTS]
        });
        setDeletedCards && setDeletedCards(prev => [...prev, ...ids]);
        toggleModal(ModalKeys.shoppingCart, false);
        navigate('/streaming/events/booked');
      } else {
        client.refetchQueries({
          include: [GET_CART, GET_BUYER_UPCOMING_EVENTS]
        });
        onClose();
        unselectAll(MatchTypesEnum.list);
        navigate('/streaming/events/booked');
        clearAll();
      }
    },
    variables: {
      input: {
        organizationId: selectedProject?.id
      }
    }
  });

  const onBookMatches = useCallback(() => {
    if (!Object.values(selectedCountries).every(el => el.length)) {
      toggleModal(ModalKeys.confirm, true, {
        title: t('cart:dont_have_countries'),
        message: t('cart:want_book'),
        confirmText: t('common:yes'),
        cancelText: t('common:no'),
        onConfirm: () => {
          bookMatches();
        }
      });
    } else bookMatches();
  }, [bookMatches, selectedCountries, t, toggleModal]);

  const events = useMemo(
    () =>
      data?.cart?.data.matches?.filter(
        elem => !deletedCards.includes(elem.match.id)
      ) || [],
    [data?.cart?.data.matches, deletedCards]
  );

  const totalPrice = useMemo(() => {
    return events.reduce((accEv, valEv) => {
      const itemPrice =
        selectedCountries[valEv.match.id]?.reduce(
          (acc, val) => (val?.country !== ALL ? acc + Number(val.price) : 0),
          0
        ) || 0;

      return accEv + itemPrice;
    }, 0);
  }, [events, selectedCountries]);

  const isBookDisabled = useMemo(() => {
    let isActive = true;

    Object.values(selectedCountries).forEach(item => {
      if (item.length) {
        isActive = false;

        return;
      }
    });

    return isActive;
  }, [selectedCountries]);

  const clearAll = () => {
    deleteCart({
      variables: {
        ...(selectedProject?.id ? { organizationId: selectedProject.id } : {}),
        input: {
          matchIds: events.map(function (item) {
            return item.match.id;
          })
        }
      }
    });
    unselectAll(MatchTypesEnum.list);
    onClose();
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const matches = events?.map(item => {
      const countries = item.availableCountries?.filter(country => {
        return (
          !country?.booked && item.countries?.includes(country.id as string)
        );
      });

      return {
        id: item.match.id,
        countries: countries || []
      };
    });

    if (matches?.length) {
      initMatches(matches, MatchTypesEnum.list);
    }
  }, [events, events.length, initMatches]);

  useEffect(() => {
    if (!(cartLoading || cartLoadingOnRefetch) && !events.length) {
      onClose();
    }
  }, [cartLoading, cartLoadingOnRefetch, events.length, onClose]);

  return (
    <Modal
      title={t('title')}
      subtitle={t('description')}
      isVisible={shoppingCart.visible}
      onClose={onClose}
      width="25%"
      transparent
      outSideClick={false}
    >
      <ModalContent>
        <div className="flex-display flex-direction-column pv--24 ph--0 gap--40">
          {events.map(item => (
            <MatchSidebarItem
              outOfPackage={item?.isOutOfPackage || false}
              key={`cart-item-${item.match.id}`}
              id={item.match.id}
              teams={[item.match.team1, item.match.team2]}
              date={item.match.startTime}
              league={item.match.league.name}
              country={item.match.region}
              status={item.match.status}
              sport={item.match.sport}
              isShoppingCard
              countries={item.availableCountries}
              initialCountries={item.countries || []}
              isLive={item.match.status === MATCH_STATUS_IDS.live}
              shouldApplyToAll={events.length > 1}
              isThereEvents={events.length}
              setDeletedCards={setDeletedCards}
            />
          ))}
        </div>
      </ModalContent>
      <ModalFooter justify="space-between">
        <div className="flex-display flex-direction-column gap--12">
          <Text>{t('total_price')}</Text>
          {(cartLoading || cartLoadingOnRefetch) && (
            <Loading overlay blurred={0.1} />
          )}
          <Text level={11} bold color="var(--black)">
            {money(totalPrice)}
          </Text>
        </div>
        <div className="flex-display">
          <Button
            className="mr--8"
            onClick={clearAll}
            flexibility="content-size"
            color="contrastNew"
            cornerRadius="smooth"
          >
            {t('clear_all')}
          </Button>
          <Private
            routeKey={permissionConstants.MANUAL_BOOK}
            routeId={permissionConstants.BUYER_ROUTES_IDS.list}
          >
            <Button
              color="confirmNew"
              cornerRadius="smooth"
              onClick={onBookMatches}
              disabled={isBookDisabled}
              loading={loading}
              flexibility="content-size"
            >
              {t('book')}
            </Button>
          </Private>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default memo(ShoppingCartModal);
