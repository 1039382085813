import { useMemo, useEffect } from 'react';
import { Select } from '@shared_medialab/ui_components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { setEventsFilterItem } from 'store/slice/filters';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectEventsFilters } from 'store/slice/filters/events/selector';
import {
  useFiltersStateContext,
  useFiltersDispatchContext
} from 'providers/Filters';
import { useInfiniteScroll } from 'hooks';

import { RouteParams } from '../../../Content/types';
import usePreviousNonNullish from 'hooks/usePreviousNonNullish';

export const LEAGUE_PAGE_SIZE = 20;

const SelectLeague = () => {
  const { t } = useTranslation('events');
  const { type } = useParams() as RouteParams;
  const eventsFilters = useAppSelector(selectEventsFilters(type));
  const dispatch = useAppDispatch();

  const { setSearch } = useFiltersDispatchContext();

  const { fetchMoreFilters, filters, searches, filtersVariables } =
    useFiltersStateContext();

  const data = usePreviousNonNullish(filters.leagues);

  const leagues = useMemo(() => data?.results || [], [data]);

  const leaguesTotal = useMemo(() => data?.total || 0, [data]);

  // scroll
  const { onScroll, stopPaging, startPaging } = useInfiniteScroll({
    callback: page => {
      if (page > 0 && fetchMoreFilters) {
        return fetchMoreFilters({
          variables: {
            input: {
              ...filtersVariables,
              leagues: {
                search: searches.leagues,
                pagination: {
                  limit: LEAGUE_PAGE_SIZE,
                  page: page
                },
                leagueIds: eventsFilters.leagueIds,
                isCombined: true
              }
            }
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            const newResults =
              fetchMoreResult.getBuyerFilters?.data.leagues?.results || [];

            if (!prev.getBuyerFilters?.data.leagues?.results) return prev;

            return {
              ...prev,
              getBuyerFilters: {
                ...prev.getBuyerFilters,
                data: {
                  ...prev.getBuyerFilters.data,
                  leagues: {
                    ...prev.getBuyerFilters.data.leagues,
                    results: [
                      ...prev.getBuyerFilters.data.leagues.results,
                      ...newResults
                    ]
                  }
                }
              }
            };
          }
        });
      } else return Promise.resolve();
    }
  });

  useEffect(() => {
    if (leagues?.length && leaguesTotal && leagues.length >= leaguesTotal) {
      stopPaging();
    } else startPaging();
  }, [stopPaging, leagues.length, leaguesTotal, startPaging]);

  return (
    <Select
      label={t('leagues')}
      showSelectAll={false}
      total={leaguesTotal}
      placeholder={t('select_leagues')}
      options={leagues.map(item => ({
        label: item.name,
        value: item.creationId,
        subTitle: `${item?.regionName} - ID: ${item.creationId}`,
        icon: String(item?.sportName)
      }))}
      value={eventsFilters.leagueIds}
      onChange={value => {
        dispatch(
          setEventsFilterItem({
            name: 'leagueIds',
            value,
            type
          })
        );
      }}
      onSearch={e => {
        setSearch({
          search: e.target.value,
          type: 'leagues'
        });
      }}
      searchValue={searches.leagues}
      onClose={() =>
        setSearch({
          search: '',
          type: 'leagues'
        })
      }
      onScroll={onScroll}
      cornerRadius="smooth"
      mode="multiple"
    />
  );
};

export default SelectLeague;
