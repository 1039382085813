import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Copy,
  PageContainer,
  Pagination2,
  Table,
  highlightedData
} from '@shared_medialab/ui_components';
import { useQuery } from '@apollo/client';
import { GET_BUYER_USERS } from 'gql/users/queries';
import {
  BuyerUsers,
  BuyerUsers_buyerUsers_data_results as ColumnsDataType
} from 'gql/users/types/BuyerUsers';
import { useTranslation } from 'react-i18next';
import { useUIDispatchContext } from 'providers/UI';
import { ModalKeys } from 'providers/UI/types';
import { useProjectStateContext } from 'providers/Projects';
import * as permissionConstants from 'constants/permissions';
import { usePermission } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectUsersFilters } from 'store/slice/filters/users/selectors';
import { shallowEqual } from 'react-redux';
import { ColumnType } from '@shared_medialab/ui_components/build/base/Table/types';

import { USERS_COLUMNS } from './constants';
import Private from 'components/shared/Private';
import { StyledCopy, StyledEmailsContainer } from './styled';
import usePreviousNonNullish from 'hooks/usePreviousNonNullish';

const PAGE_SIZE = 20;

const UserManagement: FC = () => {
  // translations
  const { t } = useTranslation();
  // context
  const { toggleModal } = useUIDispatchContext();
  // redux
  const filters = useAppSelector(selectUsersFilters(), shallowEqual);
  // states
  const [page, setPage] = useState(0);
  const { selectedProject } = useProjectStateContext();
  // hooks
  const { checkUserPermission } = usePermission();

  const pageValue = useMemo(() => {
    return filters.name.length || filters.role.length || filters.status.length
      ? 0
      : page;
  }, [filters.name.length, filters.role.length, filters.status.length, page]);

  // graphql
  const { data, loading } = useQuery<BuyerUsers>(GET_BUYER_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      organizationId: selectedProject?.id,
      query: {
        pagination: {
          page: pageValue,
          limit: PAGE_SIZE
        },
        keyword: filters.name,
        status: filters.status,
        roles: filters.role
      }
    }
  });

  const users = usePreviousNonNullish(data);

  const onRowClick = useCallback(
    (userId: string) => {
      const hasPermission = checkUserPermission(
        permissionConstants.BUYER_ROUTES_IDS.buyer_users,
        permissionConstants.EDIT
      );

      if (!hasPermission) return;

      toggleModal(ModalKeys.userDetails, true, {
        userId
      });
    },
    [checkUserPermission, toggleModal]
  );

  const results = users?.buyerUsers.data.results || [];
  const total = users?.buyerUsers.data.total || 0;

  const columns: ColumnType<ColumnsDataType>[] = useMemo(
    () => [
      {
        name: 'email',
        label: 'email',
        render: record => (
          <StyledEmailsContainer className="full-width flex-display flex-justify-space-between flex-align-items-center relative-position">
            <div>{highlightedData(record.email, filters.name)}</div>
            <StyledCopy
              onClick={(e: MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
              }}
            >
              <Copy copyText={record.email} transparent />
            </StyledCopy>
          </StyledEmailsContainer>
        )
      },
      ...USERS_COLUMNS
    ],
    [filters.name]
  );

  return (
    <PageContainer
      title={t('users:user_management')}
      rightAction={
        <Private
          routeId={permissionConstants.BUYER_ROUTES_IDS.buyer_users}
          routeKey={permissionConstants.INVITE}
        >
          <Button
            color="linear"
            flexibility="default"
            cornerRadius="smooth"
            onClick={() => toggleModal(ModalKeys.userDetails, true)}
          >
            {t('users:invite_user')}
          </Button>
        </Private>
      }
      footer={
        <div className="full-height p--12 flex-display flex-justify-end">
          <Pagination2
            page={pageValue}
            total={total}
            size={PAGE_SIZE}
            setPage={value => setPage(value)}
          />
        </div>
      }
    >
      <Table
        highlightedSearch={filters.name}
        columns={columns}
        loading={loading}
        data={results}
        translation={['users']}
        inChart={true}
        headerSticky={true}
        onRowClick={row => onRowClick(row.id)}
      />
    </PageContainer>
  );
};

export default UserManagement;
