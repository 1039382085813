import { useMemo, useEffect } from 'react';

import usePreviousNonNullish from './usePreviousNonNullish';
import {
  FILTER_DATA_LIMIT,
  useFiltersDispatchContext,
  useFiltersStateContext
} from '../providers/Filters';
import useInfiniteScroll from './useInfiniteScroll';

type Params = {
  sportIds?: string[];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useRegions = ({ sportIds }: Params) => {
  const { setSearch } = useFiltersDispatchContext();
  const { fetchMoreFilters, filters, searches, filtersVariables } =
    useFiltersStateContext();

  const data = usePreviousNonNullish(filters.regions);
  const regions = useMemo(() => data?.results || [], [data]);
  const regionsTotal = useMemo(() => data?.total || 0, [data]);

  const { onScroll, stopPaging, startPaging } = useInfiniteScroll({
    callback: page => {
      if (fetchMoreFilters) {
        return fetchMoreFilters({
          variables: {
            input: {
              ...filtersVariables,
              regions: {
                pagination: {
                  limit: FILTER_DATA_LIMIT,
                  page: page
                },
                search: '',
                regionIds: [],
                isCombined: true
              }
            }
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            const newResults =
              fetchMoreResult.getBuyerFilters?.data.regions?.results || [];

            if (!prev.getBuyerFilters?.data.regions?.results) return prev;

            return {
              ...prev,
              getBuyerFilters: {
                ...prev.getBuyerFilters,
                data: {
                  ...prev.getBuyerFilters?.data,
                  regions: {
                    ...prev.getBuyerFilters?.data.regions,
                    results: [
                      ...prev.getBuyerFilters.data.regions.results,
                      ...newResults
                    ]
                  }
                }
              }
            };
          }
        });
      } else return Promise.resolve();
    }
  });

  useEffect(() => {
    if (regions?.length && regionsTotal && regions.length >= regionsTotal) {
      stopPaging();
    } else startPaging();
  }, [stopPaging, regions.length, regionsTotal, startPaging]);

  return {
    regions,
    regionSearch: searches.regions,
    regionsTotal,
    onScroll,
    fetchMoreFilters,
    onRegionSearch: setSearch
  };
};

export default useRegions;
