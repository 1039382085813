import { FC } from 'react';

import MatchesGroupTitle from '../MatchesGroupTitle';
import MatchItem from '../MatchItem';
import { IDateSectionProps } from './types';
import '../Content/index.css';

const DateSection: FC<IDateSectionProps> = ({
  date,
  group,
  isSectionSelected,
  onClickBulkAction
}) => {
  return (
    <>
      <div className="header-group-title">
        <MatchesGroupTitle
          count={group.length}
          date={`${date}`}
          checked={isSectionSelected}
          action={() => onClickBulkAction(date)}
        />
      </div>
      {group.map(item => (
        <MatchItem key={`match-item-${item.id}`} id={item.id} />
      ))}
    </>
  );
};

export default DateSection;
