import { FC } from 'react';
import {
  Outlet,
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom';
import { ProductStatuses } from 'providers/Projects/types';
import AutoBookingProvider from 'providers/AutoBooking';
import ChoosePackageProvider from 'providers/ChoosePackage';
import AuthProvider from 'providers/Auth';
import ProjectProvider from 'providers/Projects';
import UIProvider from 'providers/UI';
import EventsProvider from 'providers/Events';
import FiltersProvider from 'providers/FilterProvider';
import FiltersProviderBuyer from 'providers/Filters';
import * as permissionConstants from 'constants/permissions';

import { ModalLayout, PrivateLayout } from 'components/layout';
// pages
import Events from 'pages/Events/Wrapper';
import NotFound from 'pages/NotFound';
import { EventsContent } from 'pages/Events/components';
import Reports from 'pages/Reports';
import Users from 'pages/UserManagement';
import { StatusState } from 'components/shared';
import AutoBookingDetails from 'pages/AutoBookingDetails';
import Private from 'components/shared/Private';

const routes: RouteObject[] = [
  {
    path: '',
    element: (
      <ProjectProvider>
        <AuthProvider>
          <UIProvider>
            <FiltersProviderBuyer>
              <EventsProvider>
                <FiltersProvider>
                  <Outlet />
                  <ModalLayout />
                </FiltersProvider>
              </EventsProvider>
            </FiltersProviderBuyer>
          </UIProvider>
        </AuthProvider>
      </ProjectProvider>
    ),
    children: [
      {
        path: '/streaming',
        element: <PrivateLayout />,
        children: [
          {
            path: 'notFound',
            element: <NotFound />
          },
          {
            path: 'events',
            element: <Events />,
            children: [
              {
                path: ':type',
                element: <EventsContent />
              }
            ]
          },
          {
            path: '/streaming/reporting',
            element: <Reports />
          },
          {
            path: 'users',
            element: (
              <Private
                routeKey={permissionConstants.VIEW}
                routeId={permissionConstants.BUYER_ROUTES_IDS.buyer_users}
              >
                <StatusState
                  states={{
                    product: [
                      ProductStatuses.active,
                      ProductStatuses.pending,
                      ProductStatuses.integration,
                      ProductStatuses.trial
                    ]
                  }}
                  component={<Users />}
                />
              </Private>
            )
          },
          {
            path: '',
            element: <Navigate to="/events/list" />
          },
          {
            path: '*',
            element: <NotFound />
          }
        ]
      },
      {
        path: 'autobooking/details',
        element: (
          <ChoosePackageProvider>
            <AutoBookingProvider>
              <Outlet />
            </AutoBookingProvider>
          </ChoosePackageProvider>
        ),
        children: [
          {
            path: '',
            element: <AutoBookingDetails />
          },
          {
            path: ':id',
            element: <AutoBookingDetails />
          }
        ]
      },
      {
        path: '',
        element: <Navigate to="streaming/events/list" />
      }
    ]
  }
];

const AppRouter: FC = () => {
  return <RouterProvider router={createBrowserRouter(routes)} />;
};

export default AppRouter;
