// TODO: datePicker
import 'leaflet/dist/leaflet.css';

import { PropsWithChildren, memo, useEffect, useState } from 'react';
import './style.css';
import { MapContainer, GeoJSON, ZoomControl } from 'react-leaflet';
import { BuyerSessionsReports_buyerSessionsReports_data_results as OverviewType } from 'gql/reports/types/BuyerSessionsReports';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar
} from 'recharts';
import { ISimpleDataType } from '@shared_medialab/ui_components/build/base/Table/types';
import { ReportsFiltersKeys } from 'store/slice/filters/reports/types';

import ChartsHeader from './ChartsHeader';
import {
  StyledChart,
  StyledChartsBox,
  StyledMapBox,
  StyledPie,
  StyledPieItemBox,
  StyledPieItems,
  StyledPieItemsBox,
  StyledTableBox
} from './styled';
import { IChartsProps, ISimpleDataTypePie } from './types';
import countriesData from './resources/countries.json';
import TrafficInfo from './Tables/TrafficInfoTable';
import { colorCount, CustomTooltip, getPath, hexToRGB, popup } from './helpers';
import PartnersInfo from './PartnersInfo';
import LoadingWrapper from '../LoadingWrapper';

const TriangleBar = (props: any) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const Charts = <T extends ISimpleDataType>({
  dateItem,
  setItem,
  title,
  icons,
  type,
  date,
  exportData,
  upendPage = 'chart',
  data,
  columns,
  tooltipTypeItem,
  eventLoading = false,
  dataKey,
  loading,
  total,
  paginationType = ReportsFiltersKeys.events,
  constColors,
  query
}: PropsWithChildren<IChartsProps<T>>) => {
  const [changePage, setChangePage] = useState(upendPage);
  const [areaAndExportIconShow, setAreaAndExportIconShow] = useState(false);

  const dataStreamMap =
    type === 'threePage' || type === 'map'
      ? (data as unknown as ISimpleDataTypePie[]).map(elem => {
          return {
            name: elem[dataKey?.name || ''] as string,
            value: +elem[dataKey?.value || '']
          };
        })
      : type === 'line' || type === 'chart-pie'
      ? (data as unknown as ISimpleDataTypePie[]).map(elem => {
          return {
            name: elem[dataKey?.name || ''] as string,
            value: +elem[dataKey?.value || '']
          };
        })
      : [];

  const backgroundColor: (string | undefined)[] = [];
  dataStreamMap.map(elem => {
    return backgroundColor.push(
      constColors && colorCount(elem.value, elem.name, constColors)
    );
  });
  useEffect(() => {
    setChangePage(upendPage ? upendPage : 'chart');
  }, [upendPage]);

  return (
    <StyledChart
      onMouseEnter={() => {
        setAreaAndExportIconShow(true);
      }}
      onMouseLeave={() => {
        setAreaAndExportIconShow(false);
      }}
    >
      {type === 'line' && (
        <>
          <ChartsHeader
            areaAndExportIconShow={areaAndExportIconShow}
            title={title}
            icons={icons}
            changePage={changePage}
            setChangePage={setChangePage}
            type={type}
            date={date}
            dateItem={dateItem}
            setItem={setItem}
            exportData={exportData}
            dataT={dataStreamMap}
            query={query}
          />
          {changePage === 'table' && (
            <StyledTableBox>
              <TrafficInfo
                data={data}
                total={total}
                columns={columns}
                loading={loading}
                paginationType={paginationType}
              />
            </StyledTableBox>
          )}
          {changePage === 'chart' && (
            <StyledChartsBox>
              <LoadingWrapper loading={loading || false}>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={data}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0
                    }}
                  >
                    <CartesianGrid
                      strokeDasharray="2 10"
                      opacity="0.6"
                      stroke="orange"
                    />
                    <XAxis
                      dataKey={dataKey?.name}
                      axisLine={{ stroke: 'orange', opacity: '0.3' }}
                      tickLine={{ stroke: 'orange' }}
                    />
                    <YAxis
                      axisLine={{ stroke: 'orange', opacity: '0.3' }}
                      tickLine={{ stroke: 'orange' }}
                      domain={[0, 'dataMax']}
                    />
                    <Tooltip
                      wrapperStyle={{ outline: 'none' }}
                      content={
                        <CustomTooltip type={type} typeItem={tooltipTypeItem} />
                      }
                      cursor={{ stroke: 'orange' }}
                    />
                    <Area
                      type="monotone"
                      dataKey={dataKey?.value || ''}
                      stroke="orange"
                      fill="#f8bf03"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </LoadingWrapper>
            </StyledChartsBox>
          )}
        </>
      )}
      {type === 'map' && (
        <>
          <ChartsHeader
            query={query}
            areaAndExportIconShow={areaAndExportIconShow}
            title={title}
            icons={icons}
            dateItem={dateItem}
            setItem={setItem}
            changePage={changePage}
            setChangePage={setChangePage}
            type={type}
            exportData={exportData}
          />
          {changePage === 'table' && (
            <StyledTableBox>
              <TrafficInfo
                total={total}
                data={data}
                columns={columns}
                paginationType={paginationType}
              />
            </StyledTableBox>
          )}
          {changePage === 'chart' && (
            <StyledMapBox>
              <MapContainer
                zoom={1}
                zoomControl={false}
                center={[49.505, -1]}
                scrollWheelZoom={false}
                style={{ height: '400px' }}
              >
                <LoadingWrapper loading={loading || false}>
                  <GeoJSON
                    // TODO: update react-leaflet after upgrading react to 18
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data={countriesData}
                    onEachFeature={(feature, layer: any) => {
                      layer.setStyle({
                        fillColor: 'blue',
                        color: 'none',
                        weight: 0.5,
                        fillOpacity: 0.1
                      });
                      dataStreamMap.forEach(
                        (element: { name: string; value: number }) => {
                          if (feature.properties.ADMIN === element.name) {
                            layer.setStyle({
                              fillColor:
                                constColors &&
                                colorCount(
                                  element.value,
                                  element.name,
                                  constColors
                                ),
                              color: 'none',
                              weight: 0.5,
                              fillOpacity: 0.4
                            });
                          }
                        }
                      );
                    }}
                    eventHandlers={{
                      mouseover: (e: { layer: any }) => {
                        const layer = e.layer;
                        const name = layer.feature.properties.ADMIN;
                        layer.setStyle({
                          color: 'blue'
                        });
                        const streamCount = popup(dataStreamMap, name);
                        streamCount &&
                          layer
                            .bindPopup(`${name} ${streamCount}`, {
                              className: 'my-popup'
                            })
                            .openPopup();
                      },
                      mouseout: (e: { layer: any }) => {
                        const layer = e.layer;
                        layer.setStyle({
                          color: 'none'
                        });
                        // const timer = setTimeout(() => layer.closePopup(), 1000);
                        // () => clearTimeout(timer);
                        layer.closePopup();
                      }
                    }}
                  />
                  <ZoomControl position="bottomright" zoomInTitle="h" />
                </LoadingWrapper>
              </MapContainer>
            </StyledMapBox>
          )}
        </>
      )}
      {type === 'threePage' && (
        <>
          <ChartsHeader
            query={query}
            title={title}
            dateItem={dateItem}
            setItem={setItem}
            icons={icons}
            areaAndExportIconShow={areaAndExportIconShow}
            changePage={changePage}
            setChangePage={setChangePage}
            type={type}
            date={date}
            exportData={exportData}
            dataT={dataStreamMap}
          />
          {changePage === 'table' && (
            <StyledTableBox>
              <TrafficInfo
                total={total}
                data={data}
                columns={columns}
                paginationType={paginationType}
              />
            </StyledTableBox>
          )}
          {changePage === 'chart' && (
            <StyledMapBox>
              <LoadingWrapper loading={loading || false}>
                <MapContainer
                  zoom={1}
                  zoomControl={false}
                  center={[49.505, -1]}
                  scrollWheelZoom={false}
                  style={{ height: '400px' }}
                >
                  <GeoJSON
                    // TODO: update react-leaflet after upgrading react to 18
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data={countriesData}
                    onEachFeature={(feature, layer: any) => {
                      layer.setStyle({
                        fillColor: 'blue',
                        color: 'none',
                        weight: 0.5,
                        fillOpacity: 0.1
                      });
                      (data as unknown as ISimpleDataTypePie[]).forEach(
                        element => {
                          if (
                            feature.properties.ADMIN ===
                            element[dataKey?.name || '']
                          ) {
                            layer.setStyle({
                              fillColor:
                                constColors &&
                                colorCount(
                                  +element[dataKey?.value || ''],
                                  element[dataKey?.name || ''],
                                  constColors
                                ),
                              color: 'none',
                              weight: 0.5,
                              fillOpacity: 0.4
                            });
                          }
                        }
                      );
                    }}
                    eventHandlers={{
                      mouseover: (e: { layer: any }) => {
                        const layer = e.layer;
                        const name = layer.feature.properties.ADMIN;
                        layer.setStyle({
                          color: 'blue'
                        });
                        const streamCount = popup(dataStreamMap, name);
                        streamCount &&
                          layer
                            .bindPopup(`${name} ${streamCount}`, {
                              className: 'my-popup'
                            })
                            .openPopup();
                      },
                      mouseout: (e: { layer: any }) => {
                        const layer = e.layer;
                        layer.setStyle({
                          color: 'none'
                        });
                        layer.closePopup();
                      }
                    }}
                  />
                  <ZoomControl position="bottomright" zoomInTitle="h" />
                </MapContainer>
              </LoadingWrapper>
            </StyledMapBox>
          )}
          {changePage === 'three' && (
            <StyledChartsBox className="p--12">
              <LoadingWrapper loading={loading || false}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={dataStreamMap}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip
                      wrapperStyle={{ outline: 'none' }}
                      content={<CustomTooltip />}
                      cursor={{ opacity: '0' }}
                    />
                    <Bar
                      dataKey="value"
                      fill="#8884d8"
                      shape={<TriangleBar />}
                      label={{ position: 'top' }}
                    >
                      {dataStreamMap.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={backgroundColor[index % 20]}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </LoadingWrapper>
            </StyledChartsBox>
          )}
        </>
      )}
      {type === 'chart-pie' && (
        <>
          <ChartsHeader
            query={query}
            dateItem={dateItem}
            setItem={setItem}
            areaAndExportIconShow={areaAndExportIconShow}
            title={title}
            icons={icons}
            changePage={changePage}
            setChangePage={setChangePage}
            type={type}
            date={date}
            exportData={exportData}
            dataT={dataStreamMap}
          />
          {changePage === 'table' && (
            <StyledTableBox>
              <TrafficInfo
                total={total}
                data={data}
                columns={columns}
                paginationType={paginationType}
              />
            </StyledTableBox>
          )}
          {changePage === 'chart' && (
            <StyledChartsBox>
              <LoadingWrapper loading={loading || false}>
                <div className="flex-display full-height full-width flex-justify-start">
                  <StyledPie className="flex-display flex-justify-center flex-align-items-center overflow-hidden">
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={data}
                          innerRadius={60}
                          outerRadius={100}
                          fill="#8884d8"
                          paddingAngle={1}
                          dataKey={dataKey?.value || ''}
                        >
                          {data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={
                                backgroundColor[index % backgroundColor.length]
                              }
                            />
                          ))}
                        </Pie>
                        <Tooltip
                          content={
                            <CustomTooltip
                              type={type}
                              typeItem={tooltipTypeItem}
                            />
                          }
                          wrapperStyle={{ outline: 'none' }}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </StyledPie>
                  <StyledPieItemBox className="flex-display flex-direction-column flex-justify-center flex-align-items-center">
                    {(data as unknown as ISimpleDataTypePie[]).map(
                      (elem, index) => (
                        <StyledPieItemsBox
                          key={index}
                          className="flex-display flex-align-items-center"
                        >
                          <StyledPieItems
                            className="flex-display flex-justify-center flex-align-items-center pv--8 ph--16 m--12"
                            color={hexToRGB(
                              constColors &&
                                colorCount(
                                  +elem[dataKey?.value || ''],
                                  elem[tooltipTypeItem || ''],
                                  constColors
                                ),
                              '1'
                            )}
                            backgroundColor={hexToRGB(
                              constColors &&
                                colorCount(
                                  +elem[dataKey?.value || ''],
                                  elem[tooltipTypeItem || ''],
                                  constColors
                                ),
                              '0.2'
                            )}
                          >
                            {elem[dataKey?.value || ''] !== undefined
                              ? Number(elem[dataKey?.value || ''])
                              : ''}
                          </StyledPieItems>
                          <div>{tooltipTypeItem && elem[tooltipTypeItem]}</div>
                        </StyledPieItemsBox>
                      )
                    )}
                  </StyledPieItemBox>
                </div>
              </LoadingWrapper>
            </StyledChartsBox>
          )}
        </>
      )}
      {type === 'partners-info' && (
        <>
          <ChartsHeader
            query={query}
            dateItem={dateItem}
            setItem={setItem}
            title={title}
            icons={icons}
            areaAndExportIconShow={areaAndExportIconShow}
            changePage={changePage}
            setChangePage={setChangePage}
            type={type}
            date={date}
            exportData={exportData}
            dataT={dataStreamMap}
          />
          <div className="flex-display flex-justify-center flex-align-items-cente overview-height">
            <LoadingWrapper loading={loading || false}>
              <PartnersInfo
                type="overView"
                data={data as unknown as OverviewType}
              />
            </LoadingWrapper>
          </div>
        </>
      )}
      {type === 'table' && (
        <>
          <ChartsHeader
            query={query}
            dateItem={dateItem}
            setItem={setItem}
            title={title}
            icons={icons}
            changePage={changePage}
            areaAndExportIconShow={areaAndExportIconShow}
            setChangePage={setChangePage}
            type={type}
            date={date}
            exportData={exportData}
            dataT={dataStreamMap}
          />
          <StyledTableBox size="big">
            <TrafficInfo
              data={data}
              columns={columns}
              total={total}
              loading={eventLoading}
              paginationType={paginationType}
            />
          </StyledTableBox>
        </>
      )}
    </StyledChart>
  );
};

export default memo(Charts);
