import { useQuery } from '@apollo/client';
import {
  GetBuyerFilters_getBuyerFilters_data as FiltersType,
  GetBuyerFilters
} from 'gql/filters/types/GetBuyerFilters';
import {
  createContext,
  useContext,
  FC,
  useReducer,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import { GET_FILTERS } from 'gql/filters/queries';
import { FetchMoreFunction } from '@apollo/client/react/hooks/useSuspenseQuery';
import { useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectEventsFilters } from 'store/slice/filters/events/selector';
import { useProjectStateContext } from 'providers/Projects';
import { selectReportsFilters } from 'store/slice/filters/reports/selectors';

import { RouteParams } from 'pages/Events/components/Content/types';
import {
  ActionTypes,
  Dispatch,
  DispatchContext,
  IFiltersProps,
  SearchData,
  State
} from './types';
import reducer from './reducers';

export const FILTER_DATA_LIMIT = 20;
const duplicateFields = {
  pagination: {
    page: 0,
    limit: FILTER_DATA_LIMIT
  }
};

const defaultFiltersInput = {
  leagues: {
    ...duplicateFields,
    leagueIds: [],
    isCombined: true
  },
  regions: {
    ...duplicateFields,
    regionIds: [],
    isCombined: true
  },
  sports: {
    ...duplicateFields,
    sportIds: [],
    isCombined: true
  },
  buyers: {
    buyerIds: [],
    ...duplicateFields
  },
  locations: {
    locationIds: [],
    ...duplicateFields
  }
};

const initialState: State = {
  filters: {
    __typename: 'Filters',
    leagues: {
      __typename: 'AttachedLeaguesResponse',
      results: [],
      total: 0
    },
    regions: {
      __typename: 'RegionResponse',
      results: [],
      total: 0
    },
    sports: {
      __typename: 'AttachedSportResponse',
      results: [],
      total: 0
    },
    locations: {
      __typename: 'LocationsResponse',
      results: [],
      total: 0
    }
  },
  fetchMoreFilters: undefined,
  searches: {
    leagues: '',
    locations: '',
    regions: '',
    sports: ''
  },
  filtersVariables: defaultFiltersInput
};

const FiltersStateContext = createContext<State | undefined>(undefined);
const FiltersDispatchContext = createContext<Dispatch | undefined>(undefined);

const FiltersProvider: FC<IFiltersProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState
  });

  const { selectedProject } = useProjectStateContext();

  // navigation
  const { type } = useParams() as RouteParams;
  const { pathname } = useLocation();

  const eventFilters = useAppSelector(selectEventsFilters(type));
  const reportFilters = useAppSelector(selectReportsFilters());

  const filtersInput = useMemo(() => {
    switch (pathname) {
      case '/streaming/events/list':
      case '/streaming/events/booked':
      case '/streaming/events/history':
        return {
          ...defaultFiltersInput,
          leagues: {
            ...duplicateFields,
            search: state.searches.leagues,
            leagueIds: eventFilters.leagueIds || [],
            isCombined: true
          },
          regions: {
            ...duplicateFields,
            search: state.searches.regions,
            regionIds: eventFilters.regionIds || [],
            isCombined: true
          },
          sports: {
            ...duplicateFields,
            search: state.searches.sports,
            sportIds: eventFilters.sportIds || [],
            isCombined: true
          }
        };
      case '/streaming/reporting':
        return {
          ...defaultFiltersInput,
          leagues: {
            ...duplicateFields,
            search: state.searches.leagues,
            leagueIds: reportFilters.league || [],
            isCombined: true
          },
          regions: {
            ...duplicateFields,
            search: state.searches.regions,
            regionIds: reportFilters.region || [],
            isCombined: true
          },
          sports: {
            ...duplicateFields,
            search: state.searches.sports,
            sportIds: reportFilters.sport || [],
            isCombined: true
          },
          locations: {
            ...duplicateFields,
            search: state.searches.locations,
            locationIds: reportFilters.countryNames || [],
            isCombined: true
          }
        };

      default:
        return defaultFiltersInput;
    }
  }, [
    eventFilters?.leagueIds,
    eventFilters?.regionIds,
    eventFilters?.sportIds,
    pathname,
    reportFilters?.countryNames,
    reportFilters?.league,
    reportFilters?.region,
    reportFilters?.sport,
    state.searches?.leagues,
    state.searches?.locations,
    state.searches?.regions,
    state.searches?.sports
  ]);

  const { data, fetchMore } = useQuery<GetBuyerFilters>(GET_FILTERS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        ...filtersInput
      },
      partnerId: selectedProject?.partnerId
    },
    context: {
      debounceKey: 'filter',
      debounceTimeout: 700
    }
  });

  useEffect(() => {
    if (data) {
      dispatch({
        type: ActionTypes.FILTERS_INIT,
        data: data.getBuyerFilters?.data as FiltersType
      });
    }

    dispatch({
      type: ActionTypes.FILTERS_INPUT,
      data: filtersInput
    });

    if (fetchMore) {
      dispatch({
        type: ActionTypes.FETCH_MORE,
        data: fetchMore as FetchMoreFunction<GetBuyerFilters, any>
      });
    }
  }, [data, fetchMore, filtersInput]);

  return (
    <FiltersDispatchContext.Provider value={dispatch}>
      <FiltersStateContext.Provider value={state}>
        {children}
      </FiltersStateContext.Provider>
    </FiltersDispatchContext.Provider>
  );
};

const useFiltersStateContext = (): State => {
  const context = useContext(FiltersStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useFiltersStateContext must be used within a FiltersStateContext'
    );
  }

  return context;
};

const useFiltersDispatchContext = (): DispatchContext => {
  const dispatch = useContext(FiltersDispatchContext);

  if (typeof dispatch === 'undefined') {
    throw new Error(
      'useFiltersDispatchContext must be used within a useFiltersDispatchContext'
    );
  }

  const setSearch = useCallback(
    (data: SearchData) => {
      dispatch({
        type: ActionTypes.SEARCH,
        data: data
      });
    },
    [dispatch]
  );

  return { setSearch };
};

export default FiltersProvider;
export { useFiltersStateContext, useFiltersDispatchContext };
