/* eslint-disable @typescript-eslint/no-explicit-any */
import { HistoryMatchesBuyer_historyMatchesBuyer_data_results } from 'gql/events/types/HistoryMatchesBuyer';
import { BookedMatches_bookedMatches_data_results } from 'gql/events/types/BookedMatches';
import { MatchTypesEnum } from 'constants/match';
import { BuyerListMatches_buyerListMatches_data_results } from 'gql/events/types/BuyerListMatches';
import { log } from 'logger';

import cache from '../cache/index';

export function addMatchToUpcoming(
  response: BuyerListMatches_buyerListMatches_data_results[]
) {
  cache.matches.addMatch(response, 'buyerListMatches', MatchTypesEnum.list);
}

export function updateMatchInUpcoming(
  response: [
    {
      id: string;
      data: BuyerListMatches_buyerListMatches_data_results;
    }
  ]
) {
  cache.matches.updateMatch(response, 'buyerListMatches');
}

export function deleteMatchFromUpcoming(response: [{ id: string }]) {
  cache.matches.deleteMatch(response, 'buyerListMatches', MatchTypesEnum.list);
}

export function addMatchToHistory(
  response: HistoryMatchesBuyer_historyMatchesBuyer_data_results[]
) {
  cache.matches.addMatch(
    response,
    'historyMatchesBuyer',
    MatchTypesEnum.history
  );
}

export function updateMatchInHistory(
  response: [
    {
      id: string;
      data: HistoryMatchesBuyer_historyMatchesBuyer_data_results;
    }
  ]
) {
  cache.matches.updateMatch(response, 'historyMatchesBuyer');
}

export function deleteMatchFromHistory(response: [{ id: string }]) {
  cache.matches.deleteMatch(
    response,
    'historyMatchesBuyer',
    MatchTypesEnum.history
  );
}

export function addMatchToBooked(
  response: BookedMatches_bookedMatches_data_results[]
) {
  cache.matches.addMatch(response, 'bookedMatches', MatchTypesEnum.booked);
}

export function updateMatchInBooked(
  response: [
    {
      id: string;
      data: BookedMatches_bookedMatches_data_results;
    }
  ]
) {
  cache.matches.updateMatch(response, 'bookedMatches');
}

export function deleteMatchFromBooked(response: [{ id: string }]) {
  cache.matches.deleteMatch(response, 'bookedMatches', MatchTypesEnum.booked);
}

export function streamAuthStatus(response: { data: any; error: any }) {
  cache.matches.streamAuthStatus(response);
}

export function addOrganization(response: any) {
  log('addOrganization', response);
}

export function updateOrganization(response: any) {
  cache.matches.updateOrganization(response);
}

export function deactivateOrganization(response: any) {
  log('deactivateOrganization', response);
}

export function updateUser(response: any) {
  cache.matches.updateUser(response);
}

export function updateUserGeneralStatus(response: any) {
  log('updateUserGeneralStatus', response);
}

export function updateUserRoleStatus(response: any) {
  log('updateUserRoleStatus', response);
}

export function updateUserProjects(response: any) {
  cache.matches.updateUserProjects(response);
}

interface ShoppingCartActions {
  deleteShoppingCart: () => void;
}

export function updateShoppingCart(response: {
  action: keyof ShoppingCartActions;
  matchId: number | string;
}) {
  cache.cart[`${response.action}ShoppingCart` as keyof ShoppingCartActions]();
}
