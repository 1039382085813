import { PropsWithChildren } from 'react';
import {
  PageContainer,
  Pagination2,
  Table
} from '@shared_medialab/ui_components';
import { ISimpleDataType } from '@shared_medialab/ui_components/build/base/Table/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectReportsPagination } from 'store/slice/filters/reports/selectors';
import { ReportsFiltersKeys } from 'store/slice/filters/reports/types';
import { setReportsPage } from 'store/slice/filters/reports';

import { StyledFooter } from './styled';
import { ITrafficInfoProps } from './types';
import { EVENTS_PAGE_SIZE, PAGE_SIZE } from './constants';

const TrafficInfo = <T extends ISimpleDataType>({
  data,
  columns,
  total,
  paginationType,
  loading
}: PropsWithChildren<ITrafficInfoProps<T>>) => {
  // redux
  const dispatch = useAppDispatch();
  const pagination = useAppSelector(selectReportsPagination());

  const page =
    pagination[ReportsFiltersKeys[paginationType as ReportsFiltersKeys]].page;

  const onChange = (value: number) => {
    return dispatch(
      setReportsPage({
        key: ReportsFiltersKeys[paginationType as ReportsFiltersKeys],
        page: value
      })
    );
  };

  return (
    <PageContainer
      bodyStyle={{
        height: '350px',
        background: 'var(--primary-bg-1)'
      }}
      footer={
        <StyledFooter>
          <Pagination2
            total={total}
            page={page}
            setPage={onChange}
            size={
              paginationType === ReportsFiltersKeys.events
                ? EVENTS_PAGE_SIZE
                : PAGE_SIZE
            }
          />
        </StyledFooter>
      }
    >
      <Table
        columns={columns}
        data={data}
        headerSticky={true}
        loading={loading}
        inChart={true}
        translation={['providers', 'common']}
      />
    </PageContainer>
  );
};

export default TrafficInfo;
