import { ReactNode } from 'react';
import {
  GetBuyerFilters_getBuyerFilters_data as FiltersType,
  GetBuyerFilters
} from 'gql/filters/types/GetBuyerFilters';
import { FetchMoreFunction } from '@apollo/client/react/hooks/useSuspenseQuery';
import { FilterSerachesKeys } from 'constants/filters';
import { FiltersInput } from 'types/graphql-global';

export const ActionTypes = {
  FILTERS_INIT: 'FILTERS_INIT',
  FETCH_MORE: 'FETCH_MORE',
  SEARCH: 'SEARCH',
  FILTERS_INPUT: 'FILTERS_INPUT'
} as const;

export interface IFiltersProps {
  children: ReactNode;
}

export interface SearchesTypes {
  leagues: string;
  regions: string;
  sports: string;
  locations: string;
}

export type State = {
  filters: FiltersType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchMoreFilters: FetchMoreFunction<GetBuyerFilters, any> | undefined;
  searches: SearchesTypes;
  filtersVariables: FiltersInput;
};

export type SearchData = {
  search: string;
  type: FilterSerachesKeys;
};

type FiltersInit = {
  type: typeof ActionTypes.FILTERS_INIT;
  data: FiltersType;
};

type FetchMore = {
  type: typeof ActionTypes.FETCH_MORE;
  data: FetchMoreFunction<GetBuyerFilters, any>;
};

type Search = {
  type: typeof ActionTypes.SEARCH;
  data: SearchData;
};

type FiltersVariables = {
  type: typeof ActionTypes.FILTERS_INPUT;
  data: FiltersInput;
};

export type DispatchContext = {
  setSearch: (data: SearchData) => void;
};

export type Action = FiltersInit | FetchMore | Search | FiltersVariables;

export type Dispatch = (action: Action) => void;
