import { Col, Row } from '@shared_medialab/ui_components';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const StyledCountries = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  margin: 0 ${({ theme }) => theme.sizes.xs};
  margin-bottom: 4px;
`;
export const StyledCountry = styled(Col)`
  ${({ theme }) => css`
    width: 100%;
    white-space: nowrap;
    font-size: ${theme.sizes.base};
    padding: ${theme.spaces.sm} ${theme.spaces.xl};
    background-color: ${rgba(theme.colors.light[4], 0.5)};
    margin-top: ${theme.spaces.xs};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  `}
`;
export const AsidePageWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const StyledAside = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ theme }) => css`
    background-color: ${rgba(theme.colors.light[4], 0.1)};
  `};
`;
export const StyledContent = styled.div`
  padding: 5px 10px;
  height: 100%;
  overflow: auto;
`;

export const ButtonWrapper = styled.div`
  padding: 0 5px;
  border-top: 4px solid var(--page-background);
`;

export const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => css`
    font-size: ${theme.sizes.xl};
    border-bottom: 4px solid var(--page-background);
    color: ${theme.colors.blue[8]};
    padding: 0 ${theme.spaces.base};
  `}
`;
export const StyledSubTitle = styled.div`
  font-size: ${({ theme }) => theme.sizes.base};
  color: #a8adcd;
  margin: ${({ theme }) => theme.sizes.xs} ${({ theme }) => theme.sizes.xs} 2px
    ${({ theme }) => theme.sizes.xs};
`;

export const StyledAsideContainer = styled.div<{ rightSpace: boolean }>`
  margin-right: ${({ rightSpace }) => (rightSpace ? '-20px' : '0px')};
`;
