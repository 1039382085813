import styled from '@shared_medialab/ui_components';
import { css } from 'styled-components';

import { IModalFooter } from './type';

export const ModalContent = styled.div`
  flex: 1 0 auto;
  max-height: calc(100vh - 142px);
  overflow-y: auto;
  position: relative;
  background-color: var(--Background__8);
  border-bottom: 1px solid var(--Background__9);
  ${({ theme }) => css`
    margin: ${theme.sizes.base} 0;
    padding: 0 ${theme.sizes.xxl};
  `}
`;

export const ModalFooter = styled.div<IModalFooter>`
  flex: 1 0 58px;
  max-height: 50px;
  display: flex;
  align-items: center;
  background-color: var(--Background__8);
  ${({ theme, justify = 'flex-end' }) => css`
    justify-content: ${justify};
    gap: ${theme.sizes.base};
    padding: 0 ${theme.sizes.xxl};
  `}
  button {
    width: 100px;
  }
`;
