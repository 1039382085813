import { useMutation, useQuery } from '@apollo/client';
import {
  Icon,
  NoData,
  Popover,
  useClickOutside
} from '@shared_medialab/ui_components';
import { GET_USER_PROJECTS } from 'gql/organizations/queries';
import {
  useProjectDispatchContext,
  useProjectStateContext
} from 'providers/Projects';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import { toast } from 'react-toastify';
import { DELETE_CART_BY_ORGANIZATION } from 'gql/cart/mutations';
import { UserProjects } from 'gql/organizations/types/UserProjects';
import { ProjectStatuses } from 'providers/Projects/types';
import { MatchTypesEnum } from 'constants/match';

import SubMenu from '../SubMenu';
import { ISelectProps, SubMenuRefType } from '../SubMenu/type';
import {
  StyledButtonSelect,
  StyledContent,
  StyledHeader,
  StyledIconRotate
} from './styled';
import { TooltipEllipses } from '../UserInfo/styled';
import useEllipsis from 'hooks/useEllipsesTooltip';
import { parseJson } from 'utils/object';
import { UserEnum } from 'pages/UserManagement/constants';

const SelectProject: FC<ISelectProps> = ({ placeholder }) => {
  // translations
  const { t } = useTranslation(['header', 'common']);
  // states
  const [isOpened, setOpened] = useState(false);
  const [search, setSearch] = useState('');
  // hooks
  const [ref, hasClickedOutside] = useClickOutside();
  // context
  const { selectedProject } = useProjectStateContext();
  const { selectProject } = useProjectDispatchContext();
  // refs
  const buyersMenuRef = useRef<SubMenuRefType | null>(null);
  const providersMenuRef = useRef<SubMenuRefType | null>(null);
  // graphql
  const { data, loading } = useQuery<UserProjects>(GET_USER_PROJECTS, {
    fetchPolicy: 'cache-only'
  });

  const { admin, buyer, provider } = data?.userProjects?.data || {
    [UserEnum.admin]: [],
    [UserEnum.provider]: [],
    [UserEnum.buyer]: []
  };

  useEffect(() => {
    if (hasClickedOutside && isOpened) {
      setOpened(false);
    }
  }, [hasClickedOutside, isOpened]);

  useEffect(() => {
    if (search) {
      buyersMenuRef.current?.toggle(true);
      providersMenuRef.current?.toggle(true);
    }
  }, [search]);

  const { hover, hasEllipses, handleMouseEnter, handleMouseLeave } =
    useEllipsis();

  const [deleteCartByOrganizationId] = useMutation(DELETE_CART_BY_ORGANIZATION);

  const organizationId = useMemo(
    () => localStorage.getItem('projectId') || selectedProject?.id,
    [selectedProject?.id]
  );

  const onClickArrow = useCallback(
    async (id?: string, type?: string) => {
      try {
        const response = await fetch(
          `${config.gateway_url}/users/url?role=${type}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );

        if (!response.ok) {
          const error = await response.text();
          throw new Error(
            parseJson(error)?.message ||
              'Something went wrong, lease contact support'
          );
        }

        const result = await response.json();

        if (!result?.data?.url) {
          throw new Error('Something went wrong, lease contact support');
        }

        const token = localStorage.getItem('token');

        const projectUrl = id ? `&project=${id}` : ``;
        localStorage.removeItem('reportsFilters');
        localStorage.removeItem('userFilters');
        localStorage.removeItem(`events${MatchTypesEnum.list}Filters`);
        localStorage.removeItem(`events${MatchTypesEnum.booked}Filters`);
        localStorage.removeItem(`events${MatchTypesEnum.history}Filters`);
        localStorage.removeItem(`events${MatchTypesEnum.list}FilterPresets`);
        localStorage.removeItem(`events${MatchTypesEnum.booked}FilterPresets`);
        localStorage.removeItem(`events${MatchTypesEnum.history}FilterPresets`);
        deleteCartByOrganizationId({
          fetchPolicy: 'network-only',
          variables: {
            organizationId
          },
          onCompleted() {
            window.location.href = `${result.data.url}?token=${token}${projectUrl}`;
          }
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        toast.error(err.message);
      }
    },
    [deleteCartByOrganizationId, organizationId]
  );

  const buyerSF = useMemo(
    () =>
      buyer?.filter(el => el.projectStatus !== ProjectStatuses.archived) || [],
    [buyer]
  );

  const whereRedirect = buyerSF.length
    ? buyerSF[0].id || ''
    : provider?.length
    ? provider[0].id || ''
    : admin?.length
    ? admin[0].id || ''
    : '-1';

  const whatProject = buyerSF.length
    ? buyerSF[0]
    : provider?.length
    ? provider[0]
    : admin?.length
    ? admin[0]
    : '-1';

  useEffect(() => {
    if (
      organizationId &&
      !buyer?.map(elem => elem.id?.toString()).includes(organizationId)
    ) {
      localStorage.removeItem('projectId');
      localStorage.setItem('selectedProject', JSON.stringify(whatProject));
      localStorage.setItem('projectId', whereRedirect.toString());
      onClickArrow(
        whereRedirect.toString(),
        buyerSF.length ? 'buyer' : 'admin'
      );
      buyerSF.length && selectProject(organizationId);
    }
  }, [
    buyer,
    data,
    organizationId,
    loading,
    onClickArrow,
    selectProject,
    whatProject,
    whereRedirect,
    buyerSF.length
  ]);

  const onSelectProject = useCallback(
    (id: string) => {
      selectProject(id);
      setOpened(false);

      onClickArrow(id, 'buyer');
    },
    [onClickArrow, selectProject]
  );

  const buyersData = useMemo(() => {
    const buyers =
      buyer?.map(item => ({
        id: item.id || '',
        name: item.name || '',
        onClick: () => onSelectProject(item.id || '')
      })) || [];

    return buyers.filter(item =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [buyer, onSelectProject, search]);

  const providersData = useMemo(() => {
    const providers =
      data?.userProjects?.data?.provider?.map(item => ({
        id: item.id || '',
        name: item.name || '',
        onClick: () => onSelectProject(item.id || '')
      })) || [];

    return providers.filter(item =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [data?.userProjects?.data?.provider, onSelectProject, search]);

  const isOnlyAdmin =
    !!data?.userProjects?.data?.admin?.length &&
    !data?.userProjects?.data?.buyer?.length &&
    !data?.userProjects?.data?.provider?.length;

  const isOnlyBuyer =
    data?.userProjects?.data?.buyer?.length === 1 &&
    !data?.userProjects?.data?.admin?.length &&
    !data?.userProjects?.data?.provider?.length;

  const isOnlyProvider =
    data?.userProjects?.data?.provider?.length === 1 &&
    !data?.userProjects?.data?.admin?.length &&
    !data?.userProjects?.data?.buyer?.length;

  switch (true) {
    case isOnlyAdmin:
      return <StyledHeader>{placeholder}</StyledHeader>;
    case isOnlyBuyer:
      return <StyledHeader>{buyersData[0].name}</StyledHeader>;
    case isOnlyProvider:
      return <StyledHeader>{providersData[0].name}</StyledHeader>;
  }

  return (
    <>
      {!!data?.userProjects?.data?.buyer?.length && (
        <div ref={ref}>
          <Popover
            selfSizing
            select
            destination="wrapper"
            opened={isOpened}
            content={
              <StyledContent>
                <StyledButtonSelect buttonType="search">
                  <input
                    placeholder="Search"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                  />
                  <Icon type="search" size={24} color="var(--text-color)" />
                </StyledButtonSelect>
                {!!data?.userProjects?.data?.admin?.length && (
                  <>
                    <StyledButtonSelect
                      buttonType="menu"
                      onClick={e => {
                        e.stopPropagation();
                        onClickArrow('', 'admin');
                      }}
                    >
                      {t('admin_tool')}
                    </StyledButtonSelect>
                  </>
                )}
                {buyersData.length || providersData.length ? (
                  <>
                    {!!buyersData.length && (
                      <>
                        {!providersData.length &&
                        !data?.userProjects?.data?.admin?.length ? (
                          buyersData.map(item => (
                            <StyledButtonSelect
                              key={`organization-${item.id}`}
                              buttonType="menu"
                              onClick={() => onSelectProject(item.id || '')}
                            >
                              {item.name}
                            </StyledButtonSelect>
                          ))
                        ) : (
                          <SubMenu
                            placeholder="Buyers"
                            data={buyersData}
                            searchedLetters={search}
                            ref={buyersMenuRef}
                          />
                        )}
                      </>
                    )}

                    {!!providersData.length && (
                      <>
                        {!buyersData.length &&
                        !data?.userProjects?.data?.admin?.length ? (
                          providersData.map(item => (
                            <StyledButtonSelect
                              key={`organization-${item.id}`}
                              buttonType="menu"
                              onClick={() => onSelectProject(item.id || '')}
                            >
                              {item.name}
                            </StyledButtonSelect>
                          ))
                        ) : (
                          <SubMenu
                            placeholder="Providers"
                            data={providersData}
                            ref={providersMenuRef}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <NoData
                    size="small"
                    text={t('common:no_data')}
                    transparent={false}
                  />
                )}
              </StyledContent>
            }
          >
            <StyledHeader
              className="pv--12"
              isOpened={isOpened}
              onClick={() => setOpened(prev => !prev)}
            >
              <div
                className="user_email"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {selectedProject?.name || placeholder}
                {hover && hasEllipses && (
                  <TooltipEllipses style={{ height: '45px' }}>
                    {selectedProject?.name || placeholder}
                  </TooltipEllipses>
                )}
              </div>
              <StyledIconRotate isOpen={isOpened} show={true}>
                <Icon type="arrow-down" size={18} />
              </StyledIconRotate>
            </StyledHeader>
          </Popover>
        </div>
      )}
    </>
  );
};

export default SelectProject;
