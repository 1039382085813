import { State, Action, ActionTypes } from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.FILTERS_INIT:
      return {
        ...state,
        filters: action.data
      };
    case ActionTypes.FETCH_MORE:
      return {
        ...state,
        fetchMoreFilters: action.data
      };

    case ActionTypes.SEARCH:
      // eslint-disable-next-line no-case-declarations
      const { type, search } = action.data;

      return {
        ...state,
        searches: {
          ...state.searches,
          [type]: search
        }
      };
    case ActionTypes.FILTERS_INPUT:
      return {
        ...state,
        filtersVariables: action.data
      };
    default:
      return state;
  }
};

export default reducer;
