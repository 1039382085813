import { ColumnType } from '@shared_medialab/ui_components/build/base/Table/types';
import { BuyerLocationReports_buyerLocationReports_data_results as LocationReportsType } from 'gql/reports/types/BuyerLocationReports';
import { BuyerOsReports_buyerOsReports_data_results as OsReportsType } from 'gql/reports/types/BuyerOsReports';
import { BuyerSportsReports_buyerSportsReports_data_results as SportsReportsType } from 'gql/reports/types/BuyerSportsReports';
import { BuyerViewReports_buyerViewReports_data_results as ViewReportsType } from 'gql/reports/types/BuyerViewReports';
import { BuyerSessionsReports_buyerSessionsReports_data_results as SessionsReportsType } from 'gql/reports/types/BuyerSessionsReports';
import { BuyerMatchReports_buyerMatchReports_data_results as MatchReportsType } from 'gql/reports/types/BuyerMatchReports';

export const getMatchColumns = (
  isVisible: boolean
): ColumnType<MatchReportsType>[] => [
  {
    name: 'name',
    label: 'Event ID',
    elips: true,
    width: isVisible ? '5vw' : '5vw'
  },
  {
    name: 'dimension_event',
    label: 'Event name',
    elips: true,
    width: isVisible ? '20vw' : '20vw'
  },
  {
    name: 'started_at',
    label: 'Event start date',
    elips: true,
    width: isVisible ? '7.5vw' : '9vw'
  },
  {
    name: 'stream_started_at',
    label: 'Stream start date',
    elips: true,
    width: isVisible ? '7.5vw' : '9vw'
  },
  {
    name: 'total_unique_views',
    label: 'Unique views',
    elips: true,
    width: isVisible ? '3vw' : '5vw'
  },
  {
    name: 'total_sessions',
    label: 'Total sessions',
    elips: true,
    width: isVisible ? '3vw' : '5vw'
  },
  {
    name: 'total_views_duration',
    label: 'Total views duration',
    render: record => record.total_views_duration.formattedValue,
    elips: true,
    width: isVisible ? '6vw' : '8vw'
  },
  {
    name: 'average_view_duration',
    label: 'Average view duration',
    render: record => record.average_view_duration.formattedValue,
    elips: true,
    width: isVisible ? '5vw' : '8vw'
  },
  {
    name: 'total_views_bandwidth_consumed',
    label: 'Total traffic consumed',
    render: record => record.total_views_bandwidth_consumed.formattedValue,
    elips: true,
    width: isVisible ? '5vw' : '8vw'
  },
  {
    name: 'average_view_bandwidth_consumed',
    label: 'Average traffic consumed',
    render: record => record.average_view_bandwidth_consumed.formattedValue,
    elips: true,
    width: isVisible ? '5vw' : '8vw'
  }
];
export const getOverViewColumns = (): ColumnType<SessionsReportsType>[] => [
  {
    name: 'average_view_bandwidth_consumed',
    label: 'average_view_bandwidth_consumed',
    render: record => record.average_view_bandwidth_consumed.formattedValue
  },
  {
    name: 'average_view_duration',
    label: 'average_view_duration',
    render: record => record.average_view_duration.formattedValue
  },
  {
    name: 'total_sessions',
    label: 'dimension_event'
  },
  {
    name: 'total_unique_views',
    label: 'started_at'
  },
  {
    name: 'total_views_bandwidth_consumed',
    label: 'total_sessions',
    render: record => record.total_views_bandwidth_consumed.formattedValue
  },
  {
    name: 'total_views_duration',
    label: 'total_unique_views',
    render: record => record.total_views_duration.formattedValue
  }
];
export const getUniqViewColumns = (): ColumnType<ViewReportsType>[] => [
  {
    name: 'range',
    label: 'Range'
  },
  {
    name: 'total_unique_views',
    label: 'Total views'
  }
];

export const getPopularitySportsColumns = (
  isVisible: boolean
): ColumnType<SportsReportsType>[] => [
  {
    name: 'average_view_bandwidth_consumed',
    label: 'Average traffic consumed',
    render: record => record.average_view_bandwidth_consumed.formattedValue,
    elips: true,
    width: isVisible ? '4vw' : '5.5vw'
  },
  {
    name: 'average_view_duration',
    label: 'Average view duration',
    render: record => record.average_view_duration.formattedValue,
    elips: true,
    width: isVisible ? '4vw' : '5.5vw'
  },
  {
    name: 'dimension_sport',
    label: 'Dimension sport',
    elips: true,
    width: isVisible ? '4vw' : '6vw'
  },
  {
    name: 'sport_id',
    label: 'Sport id',
    elips: true,
    width: '2vw'
  },
  {
    name: 'total_sessions',
    label: 'Total sessions',
    elips: true,
    width: '4vw'
  },
  {
    name: 'total_unique_views',
    label: 'Unique views',
    elips: true,
    width: '2vw'
  },
  {
    name: 'total_views_bandwidth_consumed',
    label: 'Total traffic consumed',
    render: record => record.total_views_bandwidth_consumed.formattedValue,
    elips: true,
    width: isVisible ? '4vw' : '5.5vw'
  },
  {
    name: 'total_views_duration',
    label: 'Total views duration',
    render: record => record.total_views_duration.formattedValue,
    elips: true,
    width: isVisible ? '4vw' : '6vw'
  }
];
export const getOsColumns = (
  isVisible: boolean
): ColumnType<OsReportsType>[] => [
  {
    name: 'average_view_bandwidth_consumed',
    label: 'Average traffic consumed',
    render: record => record.average_view_bandwidth_consumed.formattedValue,
    elips: true,
    width: '3.5vw'
  },
  {
    name: 'average_view_duration',
    label: 'Average view duration',
    render: record => record.average_view_duration.formattedValue,
    elips: true,
    width: '3.5vw'
  },
  {
    name: 'dimension_device',
    label: 'Dimension device',
    elips: true,
    width: isVisible ? '4vw' : '6vw'
  },
  {
    name: 'id',
    label: 'Id',
    elips: true,
    width: isVisible ? '4vw' : '6vw'
  },
  {
    name: 'total_sessions',
    label: 'Total sessions',
    elips: true,
    width: '3vw'
  },
  {
    name: 'total_unique_views',
    label: 'Unique views',
    elips: true,
    width: isVisible ? '2vw' : '3vw'
  },
  {
    name: 'total_views_bandwidth_consumed',
    label: 'Total traffic consumed',
    render: record => record.total_views_bandwidth_consumed.formattedValue,
    elips: true,
    width: isVisible ? '3.5vw' : '5vw'
  },
  {
    name: 'total_views_duration',
    label: 'Total views duration',
    render: record => record.total_views_duration.formattedValue,
    elips: true,
    width: isVisible ? '4vw' : '7vw'
  }
];
export const getLocationsColumns = (
  isVisible: boolean
): ColumnType<LocationReportsType>[] => [
  {
    name: 'dimension_country',
    label: 'Dimension country',
    elips: true,
    width: isVisible ? '4vw' : '5vw'
  },
  {
    name: 'total_unique_views',
    label: 'Unique views',
    elips: true,
    width: isVisible ? '3vw' : '5vw'
  },
  {
    name: 'average_view_bandwidth_consumed',
    label: 'Average traffic consumed',
    render: record => record.average_view_bandwidth_consumed.formattedValue,
    elips: true,
    width: isVisible ? '4vw' : '5vw'
  },
  {
    name: 'average_view_duration',
    label: 'Average view duration',
    render: record => record.average_view_duration.formattedValue,
    elips: true,
    width: isVisible ? '4vw' : '5vw'
  },
  {
    name: 'total_sessions',
    label: 'Total sessions',
    elips: true,
    width: isVisible ? '4vw' : '5vw'
  },
  {
    name: 'total_views_bandwidth_consumed',
    label: 'Total traffic consumed',
    render: record => record.total_views_bandwidth_consumed.formattedValue,
    elips: true,
    width: isVisible ? '4vw' : '5vw'
  },
  {
    name: 'total_views_duration',
    label: 'Total views duration',
    render: record => record.total_views_duration.formattedValue,
    elips: true,
    width: isVisible ? '6vw' : '7vw'
  }
];

export const PAGE_SIZE = 5;
export const EVENTS_PAGE_SIZE = 15;
