import { useQuery } from '@apollo/client';
import { EVENTS_PAGE_SIZE } from 'constants/events';
import { MatchTypesEnum } from 'constants/match';
import { GET_HISTORY_EVENTS } from 'gql/events/queries';
import { HistoryMatchesBuyer } from 'gql/events/types/HistoryMatchesBuyer';
import { useProjectStateContext } from 'providers/Projects';
import { useEffect, useMemo, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setEventsLoading, setEventsTotal } from 'store/slice/filters';
import {
  selectEventsPagination,
  selectFormattedEventsFilters
} from 'store/slice/filters/events/selector';

import useInfiniteScroll from './useInfiniteScroll';

const useEventsHistoryCache = () => {
  // redux
  const dispatch = useAppDispatch();

  const historyFilters = useAppSelector(
    selectFormattedEventsFilters(MatchTypesEnum.history),
    shallowEqual
  );

  const historyFiltersRef = useRef(historyFilters);

  //This ensures that the onScroll function uses the updated historyFilters values.
  useEffect(() => {
    historyFiltersRef.current = historyFilters;
  }, [historyFilters]);

  const { selectedProject } = useProjectStateContext();

  const { page } = useAppSelector(
    selectEventsPagination(MatchTypesEnum.history)
  );

  const { data: history, fetchMore } = useQuery<HistoryMatchesBuyer>(
    GET_HISTORY_EVENTS,
    {
      fetchPolicy: 'cache-only',
      variables: {
        query: {
          pagination: {
            page,
            limit: EVENTS_PAGE_SIZE
          },
          ...historyFilters
        },
        organizationId: selectedProject?.id
      },
      onCompleted(data) {
        const total = data.historyMatchesBuyer?.data.total || 0;
        dispatch(
          setEventsTotal({ type: MatchTypesEnum.history, value: total })
        );

        if (!total) {
          dispatch(
            setEventsLoading({ type: MatchTypesEnum.history, value: false })
          );
        }
      }
    }
  );

  const historyResults = useMemo(
    () => history?.historyMatchesBuyer?.data.results || [],
    [history?.historyMatchesBuyer?.data.results]
  );

  const historyTotal = useMemo(
    () => history?.historyMatchesBuyer?.data.total || 0,
    [history?.historyMatchesBuyer?.data.total]
  );

  const { onScroll, stopPaging, startPaging, isFetching, setPage } =
    useInfiniteScroll({
      callback: page => {
        if (!page) return Promise.resolve();

        const updatedFilters = historyFiltersRef.current;

        return fetchMore({
          variables: {
            query: {
              pagination: {
                page,
                limit: EVENTS_PAGE_SIZE
              },
              ...updatedFilters
            },
            organizationId: selectedProject?.id
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            const newResults =
              fetchMoreResult.historyMatchesBuyer?.data.results || [];

            if (!prev.historyMatchesBuyer?.data.results) return prev;

            return {
              ...prev,
              historyMatchesBuyer: {
                ...prev.historyMatchesBuyer,
                data: {
                  ...prev.historyMatchesBuyer.data,
                  results: [
                    ...prev.historyMatchesBuyer.data.results,
                    ...newResults
                  ]
                }
              }
            };
          }
        });
      }
    });

  useEffect(() => {
    if (
      historyResults?.length &&
      historyTotal &&
      historyResults.length >= historyTotal
    ) {
      stopPaging();
    } else startPaging();
  }, [stopPaging, historyResults.length, historyTotal, startPaging]);

  return {
    results: historyResults,
    total: historyTotal,
    onScroll: onScroll,
    setPage,
    isFetching
  };
};

export default useEventsHistoryCache;
