import { useQuery } from '@apollo/client';
import { EVENTS_PAGE_SIZE } from 'constants/events';
import { MatchTypesEnum } from 'constants/match';
import { GET_BOOKED_EVENTS } from 'gql/events/queries';
import { BookedMatches } from 'gql/events/types/BookedMatches';
import { useProjectStateContext } from 'providers/Projects';
import { useEffect, useMemo, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setEventsLoading,
  setEventsTotal,
  setIsPlayDisabled
} from 'store/slice/filters';
import {
  selectEventsPagination,
  selectFormattedEventsFilters
} from 'store/slice/filters/events/selector';

import useInfiniteScroll from './useInfiniteScroll';

const useEventsBookedCache = () => {
  // redux
  const dispatch = useAppDispatch();
  const bookedFilters = useAppSelector(
    selectFormattedEventsFilters(MatchTypesEnum.booked),
    shallowEqual
  );

  const { page } = useAppSelector(
    selectEventsPagination(MatchTypesEnum.booked)
  );

  const { selectedProject } = useProjectStateContext();

  const bookedFiltersRef = useRef(bookedFilters);

  useEffect(() => {
    bookedFiltersRef.current = bookedFilters;
  }, [bookedFilters]);

  const { data: booked, fetchMore } = useQuery<BookedMatches>(
    GET_BOOKED_EVENTS,
    {
      fetchPolicy: 'cache-only',
      variables: {
        query: {
          organizationId: selectedProject?.id,
          pagination: {
            page,
            limit: EVENTS_PAGE_SIZE
          },
          ...bookedFilters
        },
        partnerId: selectedProject?.partnerId
      },
      onCompleted(data) {
        const total = data.bookedMatches?.data.total || 0;
        dispatch(
          setIsPlayDisabled({ type: MatchTypesEnum.booked, value: false })
        );

        dispatch(setEventsTotal({ type: MatchTypesEnum.booked, value: total }));

        if (!total) {
          dispatch(
            setEventsLoading({ type: MatchTypesEnum.booked, value: false })
          );
        }
      }
    }
  );

  const bookedResults = useMemo(
    () => booked?.bookedMatches?.data.results || [],
    [booked?.bookedMatches?.data.results]
  );

  const bookedTotal = useMemo(
    () => booked?.bookedMatches?.data.total || 0,
    [booked?.bookedMatches?.data.total]
  );

  // scroll
  const { onScroll, stopPaging, startPaging, setPage, isFetching } =
    useInfiniteScroll({
      callback: page => {
        if (!page) return Promise.resolve();

        const updatedFilters = bookedFiltersRef.current;

        return fetchMore({
          variables: {
            query: {
              organizationId: selectedProject?.id,
              pagination: {
                page,
                limit: EVENTS_PAGE_SIZE
              },
              ...updatedFilters
            },
            partnerId: selectedProject?.partnerId
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            const newResults =
              fetchMoreResult.bookedMatches?.data.results || [];

            if (!prev.bookedMatches?.data.results) return prev;

            return {
              ...prev,
              bookedMatches: {
                ...prev.bookedMatches,
                data: {
                  ...prev.bookedMatches.data,
                  results: [...prev.bookedMatches.data.results, ...newResults]
                }
              }
            };
          }
        });
      }
    });

  useEffect(() => {
    if (
      bookedResults?.length &&
      bookedTotal &&
      bookedResults.length >= bookedTotal
    ) {
      stopPaging();
    } else startPaging();
  }, [stopPaging, bookedResults.length, bookedTotal, startPaging]);

  return {
    results: bookedResults,
    total: bookedTotal,
    onScroll: onScroll,
    setPage,
    isFetching
  };
};

export default useEventsBookedCache;
