import { FC } from 'react';
import { Loading } from '@shared_medialab/ui_components';

import { ILoadingWrapperProps } from './types';

const LoadingWrapper: FC<ILoadingWrapperProps> = ({ children, loading }) => {
  if (loading) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default LoadingWrapper;
