import { gql } from '@apollo/client';

export const GET_FILTERS = gql`
  query GetBuyerFilters($partnerId: String!, $input: FiltersInput) {
    getBuyerFilters(partnerId: $partnerId, input: $input) {
      data {
        leagues {
          results {
            creationId
            id
            regionName
            sportName
            name
          }
          total
        }
        locations {
          results {
            id
            name
          }
          total
        }
        regions {
          results {
            id
            creationId
            name
          }
          total
        }
        sports {
          results {
            id
            creationId
            name
          }
          total
        }
      }
    }
  }
`;
