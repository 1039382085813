import styled from 'styled-components';

export const StyledExport = styled.button`
  padding: 8px 7px;
  gap: 10px;
  background: var(--primary-bg-7);
  border: 1px solid;
  border-color: var(--vivid-cerulean);
  border-radius: 2px;
  transition: all 0.2s;
  color: var(--primary-2);
  svg {
    path {
      fill: var(--vivid-cerulean);
      transition: all 0.2s;
    }
  }
  &:not(:disabled):hover {
    background-color: var(--vivid-cerulean);
    color: var(--white);
    svg {
      path {
        fill: var(--white);
      }
    }
  }
  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
`;
