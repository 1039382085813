import { Tooltip } from '@shared_medialab/ui_components';
import { FC } from 'react';

import { BookingStatusProps } from './types';
import './index.css';

const BookingStatus: FC<BookingStatusProps> = ({ status, isHidden }) => {
  return (
    <Tooltip
      styles={{ position: 'left' }}
      title={status || 'Status'}
      className={isHidden ? 'hidden' : ''}
    >
      <div
        className={`booking-status ${
          status === 'Not Booked' ? 'status-unbooked' : 'status-partial-booked'
        }`}
      />
    </Tooltip>
  );
};

export default BookingStatus;
