import { CHANNEL } from 'constants/socket';
import { setStreamUrlError } from 'store/slice/filters';
import { MatchTypesEnum } from 'constants/match';
import { store } from 'store';
import { log } from 'logger';

import {
  addMatchToUpcoming,
  deleteMatchFromUpcoming,
  addMatchToHistory,
  deleteMatchFromHistory,
  updateMatchInUpcoming,
  updateMatchInHistory,
  addMatchToBooked,
  updateMatchInBooked,
  deleteMatchFromBooked,
  addOrganization,
  updateOrganization,
  deactivateOrganization,
  updateUser,
  updateUserGeneralStatus,
  updateUserProjects,
  updateUserRoleStatus,
  updateShoppingCart
} from './cache.action';

const handlers = [
  {
    channel: CHANNEL.ADD_MATCH_TO_UPCOMING,
    action: addMatchToUpcoming
  },
  {
    channel: CHANNEL.UPDATE_MATCH_IN_UPCOMING,
    action: updateMatchInUpcoming
  },
  {
    channel: CHANNEL.DELETE_MATCH_FROM_UPCOMING,
    action: deleteMatchFromUpcoming
  },
  {
    channel: CHANNEL.ADD_MATCH_TO_HISTORY,
    action: addMatchToHistory
  },
  {
    channel: CHANNEL.UPDATE_MATCH_IN_UPCOMING,
    action: updateMatchInHistory
  },
  {
    channel: CHANNEL.DELETE_MATCH_FROM_HISTORY,
    action: deleteMatchFromHistory
  },
  {
    channel: CHANNEL.ADD_MATCH_TO_BOOKED,
    action: addMatchToBooked
  },
  {
    channel: CHANNEL.UPDATE_MATCH_IN_BOOKED,
    action: updateMatchInBooked
  },
  {
    channel: CHANNEL.DELETE_MATCH_FROM_BOOKED,
    action: deleteMatchFromBooked
  },
  {
    channel: CHANNEL.STREAM_AUTH_STATUS,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: (res: any) => {
      store.dispatch(
        setStreamUrlError({
          type: MatchTypesEnum.list,
          value: res.error.status === 403,
          message: res.error.massage,
          matchId: res.data.matchId,
          referer: res.query.referer
        })
      );
    }
  },
  {
    channel: CHANNEL.ADD_ORGANIZATION,
    action: addOrganization
  },
  {
    channel: CHANNEL.UPDATE_ORGANIZATION,
    action: updateOrganization
  },
  {
    channel: CHANNEL.DEACTIVATE_ORGANIZATION,
    action: deactivateOrganization
  },
  {
    channel: CHANNEL.UPDATE_USER,
    action: updateUser
  },
  {
    channel: CHANNEL.UPDATE_USER_GENERAL_STATUS,
    action: updateUserGeneralStatus
  },
  {
    channel: CHANNEL.UPDATE_USER_ROLE_STATUS,
    action: updateUserRoleStatus
  },
  {
    channel: CHANNEL.UPDATE_USER_PROJECTS,
    action: updateUserProjects
  },
  {
    channel: CHANNEL.UPDATE_SHOPPING_CART,
    action: updateShoppingCart
  },
  {
    channel: CHANNEL.CONNECTION,
    action: (connection: unknown) => {
      log('connection', connection);
    }
  },
  {
    channel: CHANNEL.DISCONNECT,
    action: (disconnect: unknown) => {
      log('disconnect', disconnect);
    }
  },
  {
    channel: CHANNEL.ERROR,
    action: (error: unknown) => {
      console.error('error', error);
    }
  }
];

export default handlers;
