import { useMemo, useEffect } from 'react';
import { Select } from '@shared_medialab/ui_components';
import { setEventsFilterItem } from 'store/slice/filters';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectEventsFilters } from 'store/slice/filters/events/selector';
import {
  FILTER_DATA_LIMIT,
  useFiltersDispatchContext,
  useFiltersStateContext
} from 'providers/Filters';

import { RouteParams } from '../../../Content/types';
import usePreviousNonNullish from 'hooks/usePreviousNonNullish';
import useInfiniteScroll from 'hooks/useInfiniteScroll';

const SelectSport = ({ isLeague }: { isLeague?: boolean }) => {
  const { t } = useTranslation('events');
  const { type } = useParams() as RouteParams;
  const eventsFilters = useAppSelector(selectEventsFilters(type));
  const dispatch = useAppDispatch();

  const { setSearch } = useFiltersDispatchContext();
  const { fetchMoreFilters, filters, searches, filtersVariables } =
    useFiltersStateContext();

  const data = usePreviousNonNullish(filters.sports);

  const {
    onScroll: onSportsScroll,
    stopPaging,
    startPaging
  } = useInfiniteScroll({
    callback: page => {
      if (fetchMoreFilters) {
        return fetchMoreFilters({
          variables: {
            input: {
              ...filtersVariables,
              sports: {
                pagination: {
                  limit: FILTER_DATA_LIMIT,
                  page: page
                },
                search: '',
                sportIds: eventsFilters.sportIds,
                isCombined: isLeague
              }
            }
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            const newResults =
              fetchMoreResult.getBuyerFilters?.data.sports?.results || [];

            if (!prev.getBuyerFilters?.data.sports?.results) return prev;

            return {
              ...prev,
              getBuyerFilters: {
                ...prev.getBuyerFilters,
                data: {
                  ...prev.getBuyerFilters?.data,
                  sports: {
                    ...prev.getBuyerFilters?.data.sports,
                    results: [
                      ...prev.getBuyerFilters.data.sports.results,
                      ...newResults
                    ]
                  }
                }
              }
            };
          }
        });
      } else return Promise.resolve();
    }
  });

  const sports = useMemo(() => data?.results || [], [data]);

  const sportsTotal = useMemo(() => data?.total || 0, [data]);

  useEffect(() => {
    if (sports?.length && sportsTotal && sports.length >= sportsTotal) {
      stopPaging();
    } else startPaging();
  }, [stopPaging, sports.length, sportsTotal, startPaging]);

  return (
    <Select
      label={t('sports')}
      placeholder={t('select_sports')}
      options={sports.map((item: { creationId: string; name: string }) => ({
        label: item.name,
        value: item.creationId
      }))}
      value={eventsFilters.sportIds}
      onClose={() => {
        setSearch({
          search: '',
          type: 'sports'
        });
      }}
      onChange={value => {
        dispatch(
          setEventsFilterItem({
            name: 'sportIds',
            value,
            type
          })
        );
      }}
      onScroll={onSportsScroll}
      onSearch={e =>
        setSearch({
          search: e.target.value,
          type: 'sports'
        })
      }
      searchValue={searches.sports}
      cornerRadius="smooth"
      mode="multiple"
    />
  );
};

export default SelectSport;
