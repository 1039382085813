import { FC, memo, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Card,
  Input,
  Loading,
  Select,
  SportIcon,
  utils
} from '@shared_medialab/ui_components';
import { GET_SPORTS_BY_ATTACHED_LEAGUE } from 'gql/sports/queries';
import { useRegions } from 'hooks';
import { SportsByAttachedLeagues } from 'gql/sports/types/SportsByAttachedLeagues';
import { useAutoBookingSequenceContext } from 'providers/AutoBooking';

import { SportItem, StyledSports } from './styled';
import { ISportColumnProps } from './types';

const SportsSection: FC<ISportColumnProps> = ({
  onChange,
  value,
  selectedSportsCount,
  setRegionValue,
  parentRule
}) => {
  // states
  const [search, setSearch] = useState('');
  const [selectedRegions, setRegions] = useState<string[]>([]);
  const [selectedSport, setSelectedSport] = useState<string>('');

  // hooks
  const { regions, regionSearch, onRegionSearch } = useRegions({
    sportIds: []
  });

  // graphql
  const { data, loading } = useQuery<SportsByAttachedLeagues>(
    GET_SPORTS_BY_ATTACHED_LEAGUE,
    {
      fetchPolicy: 'no-cache',
      variables: {
        query: {
          name: search,
          regions: selectedRegions
        }
      }
    }
  );

  const onSelectSport = (val: string) => {
    onChange(val);
    setSelectedSport(val);
  };

  const idParentRule = parentRule?.sports
    ? parentRule.sports.map(item => item.id)
    : [];

  const sports = data?.sportsByAttachedLeagues?.data || [];
  const results =
    idParentRule.length > 0
      ? sports.filter(item => idParentRule.includes(item.id))
      : sports;

  const total = data?.sportsByAttachedLeagues?.data.length || 0;

  let sportsCount = 0;

  if (selectedSportsCount) {
    Object.values(selectedSportsCount).map(i => {
      if (+i[0] !== 0) {
        sportsCount++;
      }
    });
  }

  useEffect(() => {
    if (value) {
      setSelectedSport(value);
    }
  }, [value]);

  const sequence = useAutoBookingSequenceContext();

  return (
    <Card
      header={{
        title: 'Select sport',
        extra: `${sportsCount}/${results.length || total}`
      }}
      bodyStyle={{
        height: 'calc(100vh - 172px)',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      <div className="flex-display flex-direction-column gap--16">
        <div className="flex-display flex-justify-end full-width gap--4">
          <Input
            placeholder="Search"
            type="search"
            cornerRadius="smooth"
            size="default"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <Select
            icon="public"
            options={regions.map(item => ({
              label: item.name,
              value: item.creationId
            }))}
            value={selectedRegions}
            onChange={value => {
              setRegions(value);
              setRegionValue && setRegionValue('selectedRegionsOnSport', value);
            }}
            onSearch={e =>
              onRegionSearch({ search: e.target.value, type: 'regions' })
            }
            searchValue={regionSearch}
            cornerRadius="smooth"
            mode="multiple"
          />
        </div>
        {loading ? (
          <Loading />
        ) : (
          <StyledSports isDisabled={sequence.sportIsDisable}>
            {results.map(item => {
              return (
                <SportItem
                  key={`sport-item-${item.id}`}
                  active={selectedSport === item.creationId}
                  onClick={() => {
                    if (selectedSport === item.creationId) {
                      return onSelectSport('');
                    }

                    onSelectSport(item.creationId);
                  }}
                >
                  <div className="flex-display flex-align-items-center gap--24">
                    <SportIcon name={item.name} size={utils.rem(22)} />
                    {item.name}
                  </div>
                  {selectedSportsCount?.[item.creationId]
                    ? selectedSportsCount[item.creationId]
                    : 0}
                  /
                  {idParentRule.length > 0
                    ? selectedSportsCount?.[item.creationId]
                    : item.leaguesCount}
                </SportItem>
              );
            })}
          </StyledSports>
        )}
      </div>
    </Card>
  );
};

export default memo(SportsSection);
